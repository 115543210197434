import * as React from 'react';
import { FC, useCallback, useEffect, memo, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import _ from 'lodash';

import { getReportFileByName, getResult, getTaskStatus } from '../../api/api';
import { actionCreators } from '../../redux';
import { getUserInfo, getUserJobId } from '../../redux/reducers/userInfoReducer';
import {
    getFinalTaskResultStatus,
    getLoadedStatus,
    getTaskResultStatus,
    getUploadError,
    getUploadStatusInfo
} from '../../redux/reducers/resultInfoReducer';

import { ShapeEL } from './IUploadPage';
import { Shape, ShapeLabel } from '../../enums/Enums';
import { OrNull } from '../../types/generic';
import { getJobId } from '../../utils/getJobId';
import useInterval from '../../hooks/useInterval';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import PreviewPage from '../previewPage/PreviewPage';
import ResultPage from '../resultPage/ResultPage';
import HelpPage from '../helpPage/HelpPage';
import Header from '../header/Header';
import DropZone, { supportedFileFormats } from '../dropzone/Dropzone';
import CustomInput from '../input/Input';
import CustomButton from '../button/Button';
import { SHOW_SHAPE_DIMENSIONS_INPUTS } from '../../constants/constants';

import './UploadPage.scss';

const ShapeOptions: ShapeEL[] = [
    {value: Shape.Rectangle, label: ShapeLabel.Rectangle, disabledHeight: false},
    {value: Shape.Square, label: ShapeLabel.Square, disabledHeight: true},
    {value: Shape.Circle, label: ShapeLabel.Circle, disabledHeight: true},
    {value: Shape.Oval, label: ShapeLabel.Oval, disabledHeight: false}
];
export const TIME_INTERVAL = 300;

const UploadPage: FC = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const dispatchThunk = useDispatch<ThunkDispatch<any, any, any>>();
    const userInfo = useSelector(getUserInfo);
    const jobId = useSelector(getUserJobId);
    const taskStatus = useSelector(getTaskResultStatus);
    const loadedStatus = useSelector(getLoadedStatus);
    const uploadStatus = useSelector(getUploadStatusInfo);
    const finalTaskStatus = useSelector(getFinalTaskResultStatus);
    const uploadError = useSelector(getUploadError);

    const { shape: userShape, width: userWidth, height: userHeight } = userInfo;
    const [setInterval, clearInterval] = useInterval();
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState<string>('upload');
    const [preflightedFile, setPreflightedFile] = useState('');
    const [previewImage, setPreviewImage] = useState({
        imageUrl: '',
        originHeight: 0,
        originWidth: 0,
    });
    const [result, setResult] = useState({
        preflightedFile: '',
        previewImage: '',
        report: '',
        status: '',
        scaleToTrimbox: false,
        width: null,
        height: null
    });
    const [resultError, setResultError] = useState({ error: '', status: '' });

    const {
        onChangeJobId,
        onChangeShape,
        onChangeWidth,
        onChangeHeight,
        onChangeLoadingStatus,
        onChangeTaskStatus,
        onChangeUploadStatusInfo,
        setUploadError,
    } = useMemo(
        () => bindActionCreators(actionCreators, dispatch),
        [dispatch]
    );
    const loadLineStyle = useMemo(() =>
        ['uploading', 'processing', 'pending'].includes(taskStatus.status) ? "active-loading-line": "disabled-loading-line"
    , [taskStatus]);
    const isTaskFailed = useMemo(() => ['error', ''].includes(taskStatus.status), [taskStatus]);
    const isDropZoneDisabled = useMemo(() =>
        userWidth === '' ||
        userHeight === '' ||
        _.isNull(userShape) ||
        _.isNull(userShape.value) ||
        !isTaskFailed
    , [userWidth, userHeight, userShape, taskStatus]);

    const resetUserValues = () => {
        onChangeTaskStatus({ status: '' });
        onChangeWidth('');
        onChangeHeight('');
        onChangeShape(null);
        onChangeUploadStatusInfo({
            progress: 0,
            estimated: 0
        });
        setResultError({ error: '', status: '' });
        setUploadError({ error: '' });
    };

    const handleSelectShapeChange = useCallback((selectedOption: OrNull<ShapeEL>) => {
        onChangeShape(selectedOption);
        if (selectedOption?.disabledHeight) {
            onChangeHeight(userWidth);
        }
    }, [onChangeShape, onChangeHeight, userWidth]);

    const changeActiveTab = useCallback((value: string) => {
        setActiveTab(value === '' ? loadedStatus ? 'edit' : 'upload' : value);
    }, [setActiveTab, loadedStatus]);

    useEffect(() => {
        if (!jobId) {
            onChangeJobId(getJobId());
        }
    }, []);

    useEffect(() => {
        if (userShape?.disabledHeight) {
            onChangeHeight(userWidth);
        }
    }, [userWidth, userShape, onChangeHeight]);

    useEffect(() => {
        const queryWidth = searchParams.get('width');
        const queryHeight = searchParams.get('height');
        const width = !_.isNull(queryWidth) && isFinite(Number(queryWidth)) ? queryWidth : '';
        const height = !_.isNull(queryHeight) && isFinite(Number(queryHeight)) ? queryHeight : '';
        const shape = _.find(ShapeOptions, option => option.value === searchParams.get('shape')) || null;

        onChangeWidth(width);
        onChangeHeight(height);
        onChangeShape(shape);
    }, [onChangeWidth, onChangeHeight, onChangeShape]);

    useEffect(() => {
        const shape = (_.isNull(userShape) || _.isNull(userShape.value)) ? null : { shape: userShape.value };
        const width = userWidth === '' ? '' : { width: userWidth };
        const height = userHeight === '' ? '' : { height: userHeight };

        const params = _.assign({}, shape, width, height);
        setSearchParams(params);
    }, [userShape, userWidth, userHeight, setSearchParams]);

    useEffect(() => {
        if (taskStatus.status === 'processing' || taskStatus.status === 'pending') {
            setInterval(() => dispatchThunk(getTaskStatus(jobId)), TIME_INTERVAL);
        } else if (taskStatus.status === 'completed') {
            clearInterval();
            dispatchThunk(getResult(jobId)).then(result => {
                if (result?.error) {
                    setResultError(result);
                } else {
                    setResult(result);
                }
            });
        } else if (taskStatus.status === 'failed') {
            dispatchThunk(getResult(jobId)).then(result => setResultError(result));
            clearInterval();
        }
    }, [taskStatus]);

    useEffect(() => {
        const fetchData = async () => {
            if (taskStatus.status === 'completed'){
                if (!_.isUndefined(result.previewImage)){
                    await dispatchThunk(getReportFileByName(result.previewImage, jobId)).then((blob) => {
                        const img = new Image();
                        const imageUrl = URL.createObjectURL(blob as Blob);
                        img.src = imageUrl;
                        img.onload = () => {
                            const { naturalWidth: width, naturalHeight: height } = img;
                            setPreviewImage({ imageUrl:imageUrl,
                                originHeight:height,
                                originWidth:width});
                        };
                    });

                    await dispatchThunk(getReportFileByName(result.preflightedFile, jobId)).then((blob) => {
                        const fileUrl = URL.createObjectURL(blob as Blob);
                        setPreflightedFile(fileUrl);
                    });

                } else {
                    await dispatchThunk(getReportFileByName(result.previewImage, jobId)).then((blob) => {
                        const imgError = new Image();
                        const imageErrorUrl = URL.createObjectURL(blob as Blob);
                        imgError.src = imageErrorUrl;
                        imgError.onload = () => {
                            const { naturalWidth: width, naturalHeight: height } = imgError;
                            setPreviewImage({
                                imageUrl: imageErrorUrl,
                                originHeight: height,
                                originWidth: width
                            });
                        };
                    });
                }
                await onChangeLoadingStatus(true);
            }
        };
        fetchData().catch(console.error);
    },[result, setPreviewImage, setPreflightedFile, onChangeLoadingStatus]);

    useEffect(() => {
        setActiveTab(taskStatus.status === 'completed' ? 'edit' : 'upload');
    }, [taskStatus, setActiveTab]);

    const isDeterminateProgress = useMemo(() => {
        return ![0, 100].includes(Number.parseInt(uploadStatus.progress));
    }, [uploadStatus]);
    
    const isIndeterminateProgress = useMemo(() => {
        return Number.parseInt(uploadStatus.progress) === 100;
    }, [uploadStatus]);

    return (
        <div className="app">
            <Header active={activeTab} onChangeActiveTab={changeActiveTab} />
            {activeTab === 'help' && <HelpPage active={activeTab === 'help'} />}
            {activeTab === 'upload' &&
              <div className={(taskStatus.status === 'completed') ? 'disabled-upload-page' : 'enabled-upload-page app-wrapper'}>
                  <main>
                      { SHOW_SHAPE_DIMENSIONS_INPUTS &&
                        <div className="user-info">
                            <div className="select-shape">
                                <label className="select-label">Shape</label>
                                <Select options={ShapeOptions}
                                    placeholder="Select shape"
                                    classNamePrefix="select-shape-of"
                                    isDisabled={!isTaskFailed}
                                    value={userShape}
                                    onChange={handleSelectShapeChange}
                                />
                            </div>
                            <CustomInput
                                name='width'
                                label={!_.isNull(userShape) && userShape.disabledHeight ? 'Width and Height (mm)' : 'Width (mm)'}
                                value={userWidth}
                                hidden={false}
                                disabled={!isTaskFailed}
                                onChange={onChangeWidth}
                            />
                            <CustomInput
                                name='height'
                                label='Height (mm)'
                                value={userHeight}
                                hidden={!_.isNull(userShape) && userShape.disabledHeight}
                                disabled={!isTaskFailed}
                                onChange={onChangeHeight}
                            />
                        </div>
                      }
                      <div className="load-doc">
                          <h2>Upload artwork</h2>
                          <h3>
                              Please select the shape of the artwork you want to check, together with its width and height (in mm).
                              <br/>
                              Then upload your artwork to start the verification.
                          </h3>
                          <h3>Supported file types:</h3>
                          <div className="types">{supportedFileFormats}</div>
                          <DropZone active={taskStatus.status === ''} disabled={isDropZoneDisabled} />
                          <div className="loading-status">
                              <div className="task-status">{(taskStatus.status === '') ? '' : (`${taskStatus.status === 'pending' || taskStatus.status === 'completed' ? 'processing' : taskStatus.status}`)}</div>
                              <div className="task-status-error">{taskStatus.status === 'failed' ? `${uploadError?.error ||  resultError?.error}` : ''}</div>
                              {isDeterminateProgress &&
                                <div className="upload-status-container">
                                    {taskStatus.status !== 'failed' && (<div className="uploadStatus">
                                        <div className="progress">{`${uploadStatus.progress}%`}</div>
                                        <div>{`${uploadStatus.estimated} seconds remaining`}</div>
                                    </div>)}
                                    <div className={`load-line ${loadLineStyle}`}>
                                        <Box sx={{ width: '100%' }}>
                                            <LinearProgress variant="determinate" value={parseInt(uploadStatus.progress)} />
                                        </Box>
                                    </div>
                                </div>
                              }
                              {isIndeterminateProgress &&
                                  <div className="upload-status-container">
                                      <div className={`load-line ${loadLineStyle}`}>
                                          <Box sx={{ width: '100%' }}>
                                              <LinearProgress variant="indeterminate" />
                                          </Box>
                                      </div>
                                  </div>
                              }
                              <CustomButton
                                  name="Check another"
                                  disabled={false}
                                  active={taskStatus.status === 'failed'}
                                  onClick={resetUserValues}
                              />
                          </div>
                      </div>
                  </main>
              </div>
            }
            {(taskStatus.status === 'completed') && activeTab !== 'help' && activeTab !== 'upload' && activeTab !== 'preview' &&
              <div className={`result-page-${(taskStatus.status === 'completed') && 
              activeTab !== 'help' && activeTab !== 'preview' ? 'loaded app-wrapper' : 'unloaded'}`}>
                  <ResultPage
                      previewImage={previewImage}
                      preflightedFile={preflightedFile}
                      preflightedFileName={_.isUndefined(result.preflightedFile) ? 'Template' : result.preflightedFile}
                      width={result.width}
                      height={result.height}
                      onChangeActiveTab={changeActiveTab}
                      loaded={loadedStatus}
                  />
              </div>
            }
            {(activeTab === 'preview' || ['pending', 'processing', 'completed'].includes(finalTaskStatus.status)) &&
              <PreviewPage
                  active={activeTab === 'preview'}
                  onChangeActiveTab={changeActiveTab}
              />
            }
        </div>
    );
};

export default UploadPage;
