import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { fabric } from 'fabric';
import _ from 'lodash';

import { faUpload } from '@fortawesome/pro-light-svg-icons';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioButtonUncheckedSharpIcon from '@mui/icons-material/RadioButtonUncheckedSharp';
import RadioButtonCheckedSharpIcon from '@mui/icons-material/RadioButtonCheckedSharp';

import { getFinalTaskStatus, getReportFileByName, getResult } from '../../api/api';
import {onChangeLoadingStatus, setFinalErrorTaskStatusMessage} from '../../redux/actionCreators';
import { getUserInfo, getUserJobId } from '../../redux/reducers/userInfoReducer';
import { getFinalErrorTaskStatusMessage, getFinalTaskResultStatus } from '../../redux/reducers/resultInfoReducer';
import useInterval from '../../hooks/useInterval';
import { Shape } from '../../enums/Enums';
import { TIME_INTERVAL } from '../uploadPage/UploadPage';
import { IPreviewPage } from './IPreviewPage';
import CustomButton from '../button/Button';
import { SAFETY_LINE, BLEED_LINE, REPORT_URL, SCROLL_STEP } from '../../constants/constants';
import { sendAddToOrderMessage } from '../../utils/messageService';
import { updateObjectsPosition } from '../resultPage/ResultPage';

import './PreviewPage.scss';

const CANVAS_PADDING = 30;

const PreviewPage: React.FC<IPreviewPage> = (props) => {
    const dispatchThunk = useDispatch<ThunkDispatch<any, any, any>>();
    const userInfo = useSelector(getUserInfo);
    const jobId = useSelector(getUserJobId);
    const finalTaskStatus = useSelector(getFinalTaskResultStatus);
    const finalErrorTaskStatusMessage = useSelector(getFinalErrorTaskStatusMessage);
    const [setInterval, clearInterval] = useInterval();
    const [mouseTopPosition, setMouseTopPosition] = useState<any>(0);

    const refContainer = useRef(null);
    const [finalResult, setResult] = useState({
        previewImage: '',
        preflightedFile: '',
        proofImage: '',
        report: '',
        status: '',
        width: null,
        height: null,
    });
    const [resultError, setResultError] = useState({ error: '', status: '' });
    const [previewImage, setPreviewImage] = useState({
        imageUrl: '',
        originHeight: 0,
        originWidth: 0
    });
    const [proofImage, setProofImage] = useState({
        imageUrl: '',
        originHeight: 0,
        originWidth: 0
    });
    const [preflightedFile, setPreflightedFile] = useState('');

    const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);
    const [dimensions, setDimensions] = useState<any>({
        width: null,
        height: null,
    });
    const [coefficientScaling, setCoefficientScaling] = useState<number>(1);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if ((finalTaskStatus.status === 'processing' || finalTaskStatus.status === 'pending')) {
            setInterval(() => dispatchThunk(getFinalTaskStatus(jobId)), TIME_INTERVAL);
        } else if (finalTaskStatus.status === 'completed') {
            clearInterval();
            dispatchThunk(getResult(jobId)).then(finalResult => setResult(finalResult));
        } else if (finalTaskStatus.status === 'failed') {
            dispatchThunk(getResult(jobId)).then(finalResult => setResultError(finalResult));
            clearInterval();
        }
    }, [finalTaskStatus]);

    useEffect(() => {
        const fetchData = async () => {
            if (finalTaskStatus.status === 'completed') {
                if (!_.isUndefined(finalResult.previewImage)) {
                    await dispatchThunk(getReportFileByName(finalResult.previewImage, jobId)).then((blob) => {
                        const img = new Image();
                        const imageUrl = URL.createObjectURL(blob as Blob);
                        img.src = imageUrl;
                        img.onload = () => {
                            const {naturalWidth: width, naturalHeight: height} = img;
                            setPreviewImage({
                                imageUrl: imageUrl,
                                originHeight: height,
                                originWidth: width
                            });
                        };
                    });
                }
                if (!_.isUndefined(finalResult.preflightedFile)) {
                    await dispatchThunk(getReportFileByName(finalResult.preflightedFile, jobId)).then((blob) => {
                        const fileUrl = URL.createObjectURL(blob as Blob);
                        setPreflightedFile(fileUrl);
                    });
                }
                await dispatchThunk(onChangeLoadingStatus(true));
                if (!_.isUndefined(finalResult.proofImage)) {
                    await dispatchThunk(getReportFileByName( finalResult.proofImage, jobId)).then((blob) => {
                        const img = new Image();
                        const imageUrl = URL.createObjectURL(blob as Blob);
                        img.src = imageUrl;
                        img.onload = () => {
                            const { naturalWidth: width, naturalHeight: height } = img;
                            setProofImage({
                                imageUrl: imageUrl,
                                originHeight: height,
                                originWidth: width
                            });
                        };
                    });
                }
            }
        };
        fetchData().catch(console.error);
    }, [finalResult]);

    const onFinish = useCallback(() => {
        sendAddToOrderMessage({
            width: userInfo.width,
            height: +userInfo.height,
            shape: userInfo.shape.value,
            file: `${REPORT_URL}/${finalResult.preflightedFile}`,
        });
    }, [finalResult, userInfo]);

    useEffect(() => {
        if (refContainer.current) {
            setDimensions({
                width: refContainer.current['offsetWidth'],
                height: refContainer.current['offsetHeight'],
            });
        }
    }, [refContainer.current]);

    const generateCutLine = useCallback((newCanvas: fabric.Canvas, coefficientBaseScaling: number) => {
        if (newCanvas) {
            let newСutLine;
            const rect =  new fabric.Rect({
                width: dimensions.width + 1, height: dimensions.height + 1, fill: '#dce9fc', left: -1, top: -1,
                evented: false, opacity: 0.3, selectable: false, hasControls: false
            });

            const left = rect.width ? (-(+userInfo.width * coefficientBaseScaling)/2) : 0;
            const top = rect.height ? (-(+userInfo.height * coefficientBaseScaling)/2) : 0;

            switch (userInfo.shape?.value) {
            case Shape.Circle :
                newСutLine = new fabric.Circle({
                    radius: userInfo.width / 2 * coefficientBaseScaling ,
                    left: left,
                    top:  top,
                    fill: '', inverted: true, hoverCursor: 'auto'});
                break;
            case Shape.Oval :
                newСutLine = new fabric.Ellipse({
                    rx: userInfo.width / 2 * coefficientBaseScaling,
                    ry: userInfo.height / 2 * coefficientBaseScaling,
                    left: left,
                    top:  top,
                    fill: '', inverted: true, hoverCursor: 'auto'});
                break;
            default:
                newСutLine = new fabric.Rect({
                    width: userInfo.width * coefficientBaseScaling,
                    height : userInfo.height * coefficientBaseScaling,
                    left: left,
                    top:  top,
                    fill: '', inverted: true, hoverCursor: 'auto'});
                break;
            }
            rect.setOptions({ name: "newСutLine"});
            rect.clipPath = newСutLine;
            newCanvas.centerObject(rect);
            newCanvas.insertAt(rect, 0, false);
            rect.bringToFront();

            return newCanvas;
        }
    }, [userInfo, dimensions]);

    const generateDashedCutLine = useCallback((newCanvas: fabric.Canvas, coefficientBaseScaling: number) => {
        if (newCanvas) {
            const newSafetyLine= generateLine(coefficientBaseScaling, 0);
            newSafetyLine.setOptions({ name: "newDashedCutLine", stroke: "rgb(206,7,180)", strokeDashArray: [0]});
            newCanvas.centerObject(newSafetyLine);
            newCanvas.insertAt(newSafetyLine, 1, false);
            return newCanvas;
        }
    }, [userInfo]);

    const generateLine = useCallback((coefficientBaseScaling: number, shift: number) => {
        switch (userInfo.shape?.value) {
        case Shape.Circle :
            return new fabric.Circle({
                radius: (+userInfo.width / 2 + shift / 2) * coefficientBaseScaling,
                strokeDashArray: [6],
                fill: '',
                selectable: false,
                hoverCursor: 'auto',
                evented: false
            });
        case Shape.Oval :
            return new fabric.Ellipse({
                rx: (+userInfo.width / 2 + shift / 2) * coefficientBaseScaling,
                ry: (+userInfo.height / 2 + shift / 2) * coefficientBaseScaling,
                strokeDashArray: [6],
                fill: '',
                selectable: false,
                hoverCursor: 'auto',
                evented: false
            });
        default:
            return new fabric.Rect({
                width: (+userInfo.width + shift) * coefficientBaseScaling,
                height: (+userInfo.height + shift) * coefficientBaseScaling,
                strokeDashArray: [6],
                fill: '',
                rx: 0.8 * coefficientBaseScaling,
                ry: 0.8 * coefficientBaseScaling,
                selectable: false,
                hoverCursor: 'auto',
                evented: false
            });
        }
    }, [userInfo]);

    const generateSafetyLine = useCallback((newCanvas: fabric.Canvas, coefficientBaseScaling: number) => {
        if (newCanvas) {
            const newSafetyLine= generateLine(coefficientBaseScaling, - SAFETY_LINE * 2);
            newSafetyLine.setOptions({ name: "newSafetyLine", stroke: " #21A112"});
            newCanvas.centerObject(newSafetyLine);
            newCanvas.insertAt(newSafetyLine, 2, false);
            return newCanvas;
        }
    }, [userInfo]);

    const generateBleedLine = useCallback((newCanvas: fabric.Canvas, coefficientBaseScaling: number) => {
        if (newCanvas) {
            const newBleedLine = generateLine(coefficientBaseScaling, BLEED_LINE * 2);
            newBleedLine.setOptions({ name: "newBleedLine", stroke: "rgb(6,122,255)"});
            newCanvas.centerObject(newBleedLine);
            newCanvas.insertAt(newBleedLine, 3, false);
            return newCanvas;
        }
    }, [userInfo]);

    const handleMouseMove = useCallback((options: fabric.IEvent) => {
        if (options.target?.name !== "artworkIMG") {
            if (mouseTopPosition < (options.pointer?.y ? options.pointer?.y : 0)) {
                window.scrollBy(0,-SCROLL_STEP);
            } else {
                window.scrollBy(0, SCROLL_STEP);
            }
        }
    }, [mouseTopPosition]);

    useEffect(useCallback(() => {
        if (canvas) {
            canvas.off('mouse:move');
            canvas.on('mouse:move', handleMouseMove);
        }
    }, [canvas, handleMouseMove]), [handleMouseMove]);

    const initCanvasListeners = useCallback((canvas: fabric.Canvas) => {
        canvas.on('mouse:down', function(options: fabric.IEvent) {
            if (options.target?.name !== "artworkIMG") {
                setMouseTopPosition(options.pointer?.y ? options.pointer?.y : 0);
            }
        });

        canvas.on('mouse:move', handleMouseMove);

        canvas.on('mouse:up', function(options: fabric.IEvent) {
            if (options.target?.name !== "artworkIMG") {
                setMouseTopPosition(options.pointer?.y ? options.pointer?.y : 0);
            }
        });
    }, [handleMouseMove]);

    useEffect(() => {
        if (!canvas && previewImage?.imageUrl && !_.isNil(dimensions.width) && !_.isNil(dimensions.height)) {
            let newCanvas = new fabric.Canvas('c', {
                backgroundColor: '#ffffff',
                selectionColor: 'rgba(88,130,239,0.7)',
                selectionLineWidth: 1,
                svgViewportTransformation: true,
                allowTouchScrolling: true,
                selection: false,
            });

            const scaleX = dimensions.width / (+userInfo.width + 2 * CANVAS_PADDING);
            const scaleY = dimensions.height / (+userInfo.height + 2 * CANVAS_PADDING);
            const coefficientBaseScaling = Math.min(scaleX, scaleY);
            setCoefficientScaling(coefficientScaling);

            const canvasWithCutLine= generateCutLine(newCanvas, coefficientBaseScaling);
            if (canvasWithCutLine) {
                newCanvas = canvasWithCutLine;
            }

            const canvasWithDashedCutLine= generateDashedCutLine(newCanvas, coefficientBaseScaling);
            if (canvasWithDashedCutLine) {
                newCanvas = canvasWithDashedCutLine;
            }

            fabric.Image.fromURL(previewImage.imageUrl, function(img) {
                img.setOptions({
                    name: "artworkIMG",
                    selectable: false,
                    hoverCursor: 'auto',
                    evented: false,
                });
                newCanvas.centerObject(img);
                if (img.width && img.height) {
                    const scaleXImg = ((+userInfo.width + BLEED_LINE * 2)) * coefficientBaseScaling / img.width;
                    const scaleYImg = ((+userInfo.height + BLEED_LINE * 2)) * coefficientBaseScaling / img.height;
                    const coefficientBaseScalingImg = Math.min(scaleXImg, scaleYImg);

                    img.scaleToWidth(img.width * coefficientBaseScalingImg);
                    img.scaleToHeight(img.height * coefficientBaseScalingImg);
                }

                newCanvas.centerObject(img);
                newCanvas.insertAt(img, 0, false);
            });

            const canvasWithBleedLine = generateBleedLine(newCanvas, coefficientBaseScaling);
            if (canvasWithBleedLine) {
                newCanvas = canvasWithBleedLine;
            }

            const canvasWithSafetyLine = generateSafetyLine(newCanvas, coefficientBaseScaling);
            if (canvasWithSafetyLine) {
                newCanvas = canvasWithSafetyLine;
            }
            initCanvasListeners(newCanvas);
            setCanvas(newCanvas);
        }
    }, [previewImage, dimensions, canvas, userInfo, setCoefficientScaling, finalResult]);

    const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const onBack = () => {
        dispatchThunk(setFinalErrorTaskStatusMessage(''));
        props.onChangeActiveTab( 'edit');
    };

    const onResize = useCallback(() => {
        const newCanvas = document.getElementById('c');
        const canvasContainer = document.getElementById('canvas');
        if (newCanvas && canvasContainer && canvas) {
            setDimensions({
                width: canvasContainer.clientWidth,
                height: canvasContainer.clientHeight,
            });

            canvas.setDimensions({
                width: canvasContainer.clientWidth,
                height: canvasContainer.clientHeight
            });
        }
    }, [canvas]);

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, [onResize]);

    useEffect(() => {
        if (canvas) {
            const width = refContainer.current ? dimensions.width : 0;
            const height = refContainer.current ? dimensions.height : 0;
            canvas.setDimensions({ width, height }, { cssOnly: true });

            updateObjectsPosition(canvas, 1, 1);
            canvas.renderAll();
            canvas.getObjects().forEach(function(o: any) {
                if (o.name === 'artworkIMG') {
                    canvas.viewportCenterObject(o);
                }
            });
            canvas.renderAll();
        }
    }, [canvas, dimensions]);

    return (
        <div className={props.active ? 'active-preview-page' : 'disabled-preview-page'}>
            <main>
                <nav/>
                <div id="canvas" className='main-section canvas' ref={refContainer}>
                    {(finalTaskStatus.status === 'processing' || finalTaskStatus.status === 'pending') &&
                      <div className="circular-progress-wrapper">
                          <CircularProgress />
                      </div>}
                    {finalTaskStatus.status === 'failed' && <div className="error-message">{finalErrorTaskStatusMessage.error}</div>}
                    { finalResult?.previewImage && <>
                        <canvas id="c" width={refContainer.current ? refContainer.current['offsetWidth'] : 0}
                            height={refContainer.current ? refContainer.current['offsetHeight'] : 0}/>
                        <a
                            href={proofImage.imageUrl}
                            download={finalResult.proofImage}
                            target="_blank"
                            rel="noreferrer">
                            <CustomButton name={`download proof PNG`} iconFA={faUpload} className="back-btn"
                                disabled={_.isUndefined(finalResult.proofImage) || finalResult.proofImage === ''} iconFirst={true}
                                active={true}/>
                        </a>
                    </>
                    }
                </div>
            </main>
            <footer className="page-footer">
                <CustomButton name='go back' className="back-btn" disabled={false} active={true} onClick={onBack}/>
                {finalTaskStatus.status !== 'failed' &&
                  <div className="agree-wrapper">
                      <FormControlLabel control={
                          <Checkbox
                              checked={checked}
                              onChange={handleChangeCheck}
                              icon={<RadioButtonUncheckedSharpIcon />}
                              checkedIcon={<RadioButtonCheckedSharpIcon />}
                          />}
                      label="I confirm I've downloaded, checked & approved my design"
                      />
                      <a
                          href={preflightedFile}
                          download={finalResult.preflightedFile}
                          target="_blank"
                          rel="noreferrer">
                          <CustomButton name='add to order' className="finish-btn" disabled={!checked} active={true} onClick={onFinish}/>
                      </a>
                  </div>}
            </footer>
        </div>
    );
};
export default PreviewPage;
