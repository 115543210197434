import * as React from 'react';
import { IHelpPage } from './IHelpPage';

import './HelpPage.scss';

const HelpPage: React.FC<IHelpPage> = (props) => {
    return (
        <div className={props.active ? 'active-help-page' : 'disabled-help-page'}>
            <main>
                <nav/>
                <section>
                    <h1>Help & Support</h1>
                    <div>
                        If you find any part of this test version of our upload tool unclear or challenging, please do not hesitate to get in touch. Our support team is here to help guide you through the process and address any questions or issues you may have. Simply reach out to us, and we will do our best to assist as soon as possible.
                    </div>
                </section>
            </main>
            <footer className="page-footer"/>
        </div>
    );
};
export default HelpPage;
